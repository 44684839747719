// Header.js
import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Dropdown from "react-bootstrap/Dropdown";
import Sidebar from "../Common/Sidebar";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useState } from "react";
import AdminRoute from "../../Route/RouteDetails";
import { NavLink } from "react-router-dom";
import API from "../../Api/Api";
import { formatDateTime } from "./Functions";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useNavigate } from "react-router-dom";
// import { socket } from "../../socket";

function Header() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState([]);
  const [room] = useState("notification");
  const UserData = JSON.parse(localStorage.getItem("WellnessCoachData"));
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(true);

  const [UserInfo, setUserInfo] = useState([]);
  useEffect(() => {
    setLoading(true);
    try {
      API.Profile.GetProfileData({}).then((response) => {
        if (response?.data?.status === 1) {
          // console.log(response?.data?.data);
          setUserInfo(response?.data?.data);
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  // ============== new code ================

  // useEffect(() => {
  //   if (!socket || !room) return;
  //   socket.connect();

  //   const handleData = (data) => {
  //     setNotification((list) => [data, ...list]);
  //   };

  //   socket.on(room, handleData);

  //   return () => {
  //     socket.off(room, handleData); // Use the same handler to properly remove it
  //     socket.disconnect();
  //   };
  // }, [room]);

  useEffect(() => {
    API.NotificationApis.getAllnotificationApi()
      .then((response) => {
        if (response.data.status === 1) {
          setNotification(response.data.data_all);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleMyChecklist = (val) => {
    navigate(`../${AdminRoute?.Common?.MyChecklist}`);
  };

  // const formatDateTime = (inputDate) => {
  //   const today = new Date();
  //   const date = new Date(inputDate);

  //   // Check if the date is today
  //   if (
  //     date.getDate() === today.getDate() &&
  //     date.getMonth() === today.getMonth() &&
  //     date.getFullYear() === today.getFullYear()
  //   ) {
  //     const options = { hour: 'numeric', minute: 'numeric', hour12: true };
  //     return 'Today ' + date.toLocaleTimeString(undefined, options);
  //   }

  //   // Check if the date is yesterday
  //   const yesterday = new Date(today);
  //   yesterday.setDate(today.getDate() - 1);

  //   if (
  //     date.getDate() === yesterday.getDate() &&
  //     date.getMonth() === yesterday.getMonth() &&
  //     date.getFullYear() === yesterday.getFullYear()
  //   ) {
  //     const options = { hour: 'numeric', minute: 'numeric', hour12: true };
  //     return 'Yesterday ' + date.toLocaleTimeString(undefined, options);
  //   }

  //   // If not today or yesterday, return the actual date and time
  //   const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
  //   inputDate = date.toLocaleDateString(undefined, options)
  //   const parts = inputDate.split(' at ');

  //   if (parts.length === 2) {
  //     const datePart = parts[0];
  //     const timePart = parts[1];
  //     return `${datePart} ${timePart}`;
  //   }

  //   // If the input format is not as expected, return the original string
  //   return inputDate;
  //   // return date.toLocaleDateString(undefined, options);
  // };

  return (
    <div className="header">
      <div className="toggle-menu">
        <Dropdown className="d-inline" autoClose={false}>
          <Dropdown.Toggle id="dropdown-autoclose-false"></Dropdown.Toggle>

          <Button variant="primary" onClick={handleShow}>
            <Button className="btn mb-logo" id="collapseToggle">
              <span id="toggleIcon" className="navbar-toggler-icon" />
            </Button>
          </Button>

          <Offcanvas show={show} onHide={handleClose}>
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body>
              <Sidebar />
            </Offcanvas.Body>
          </Offcanvas>

          <Dropdown.Menu></Dropdown.Menu>
        </Dropdown>
      </div>
      <ul className="navbar-nav ms-auto mt-lg-0 align-items-center">
        <li className="checklist-wrap pe-1">
          <div
            className="checklist-icon icon-div cursor-pointer"
            onClick={() => handleMyChecklist()}
          >
            <img src="images/notes-white.png" className="notes" alt="icon" />
          </div>
        </li>
        <li className="notification-dropdown">
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <div className="icon-div p-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className="icon icon-lg"
                  role="img"
                >
                  <path
                    fill="var(--ci-primary-color, currentColor)"
                    d="M450.27,348.569,406.6,267.945V184c0-83.813-68.187-152-152-152s-152,68.187-152,152v83.945L58.928,348.568A24,24,0,0,0,80.031,384h86.935c-.238,2.636-.367,5.3-.367,8a88,88,0,0,0,176,0c0-2.7-.129-5.364-.367-8h86.935a24,24,0,0,0,21.1-35.431ZM310.6,392a56,56,0,1,1-111.419-8H310.018A56.14,56.14,0,0,1,310.6,392ZM93.462,352,134.6,276.055V184a120,120,0,0,1,240,0v92.055L415.736,352Z"
                    className="ci-primary"
                  ></path>
                </svg>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {notification &&
                notification.map(
                  ({ title, description, createdAt, type, receiver_id }, i) => {
                    return (
                      <React.Fragment key={i}>
                        {type === "all" ? (
                          <Dropdown.Item>
                            <div className="preview-item-joined flex-grow py-1">
                              <div className="notification-wrapper ps-2">
                                <p className="preview-subject ellipsis font-weight-medium text-dark">
                                  {title}
                                </p>
                                <p className="fw-light small-text mb-0 grey-text">
                                  {description}
                                </p>
                                <p className="date grey-text">
                                  {formatDateTime(createdAt)}
                                </p>
                              </div>
                            </div>
                          </Dropdown.Item>
                        ) : receiver_id === UserData.userdata._id ? (
                          <Dropdown.Item>
                            <div className="preview-item-joined flex-grow py-1">
                              <div className="notification-wrapper ps-2">
                                <p className="preview-subject ellipsis font-weight-medium text-dark">
                                  {title}
                                </p>
                                <p className="fw-light small-text mb-0 grey-text">
                                  {description}
                                </p>
                                <p className="date grey-text">
                                  {formatDateTime(createdAt)}
                                </p>
                              </div>
                            </div>
                          </Dropdown.Item>
                        ) : null}
                      </React.Fragment>
                    );
                  }
                )}
            </Dropdown.Menu>
          </Dropdown>
        </li>

        <li className="account-dropdown">
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <NavLink to={AdminRoute.Common.Profile}>
                <div className="icon-div d-flex justify-content-center align-items-center p-0 ">
                  <img
                    src={
                      UserInfo?.image
                        ? UserInfo?.image
                        : "images/user-white.png"
                    }
                    className={
                      UserData?.userdata?.image
                        ? "User"
                        : "User user-default-image"
                    }
                    alt="icon"
                  />

                  {/* Need "user-default-image" fo default image ico, remove this class for uploaded image */}
                </div>
              </NavLink>
            </Dropdown.Toggle>
            {/* <Dropdown.Menu>
              < NavLink to={AdminRoute.Common.Profile}></NavLink><br></br>
              {/* < NavLink to={AdminRoute.Common.Payment}>Payments</NavLink><br></br>
              < NavLink to={AdminRoute.Common.Settings}>Settings</NavLink><br></br> */}
            {/* <Dropdown.Item href="/payment"></Dropdown.Item>
              <Dropdown.Item href="/settings"> </Dropdown.Item> */}
            {/* </Dropdown.Menu> */}
          </Dropdown>
        </li>
      </ul>
    </div>
  );
}

export default Header;
